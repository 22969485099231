<template>
  <div>
    <div class="row strong">
      <section class="banner">
        <h2 class="header">
          <translate translate-context="Content/Home/Header"
            >A Community Wealth Sharing Platform</translate
          >
        </h2>
        <p class="big">
          Retribute is an open standard designed to make the job of supporting
          the creators you like easier.
        </p>
        <a class="pure-button" href="#get-started">
          <translate translate-context="Content/Home/Button.label"
            >Get Started!</translate
          >
        </a>
        <a class="header-link" href="#learn-more">
          <translate translate-context="Content/Home/Button.Label"
            >Learn more…</translate
          >
        </a>
        <!-- <img class="strong" src="../assets/screenshots/appscreen.png" /> -->
      </section>
    </div>
    <div class="row" id="learn-more">
      <section class="wide">
        <div class="feature">
          <div class="content">
            <h3>
              <translate translate-context="Content/Home.Feature/Header"
                >Support your favorite people on your favorite
                platforms</translate
              >
            </h3>
            <p>
              <strong>
                <translate translate-context="Content/Home.Feature/Paragraph">
                  Retribute helps you to support your favorite people on
                  platforms you already know and love
                </translate>
              </strong>
            </p>
            <p>
              <translate translate-context="Content/Home.Feature/Paragraph">
                Simply sign in to your account on a supported platform and let
                Retribute do the rest.
              </translate>
            </p>
            <a href="#get-started">
              <translate translate-context="Content/Home/Button.Label"
                >Get started</translate
              >
            </a>
          </div>
          <div class="image">
            <img class="strong" src="../assets/logos/icons.png" />
          </div>
        </div>
        <div class="feature">
          <div class="content">
            <h3>
              <translate translate-context="Content/Home.Feature/Header"
                >A wide range of support methods</translate
              >
            </h3>
            <p translate-context="Content/Home.Feature/Paragraph">
              <strong>
                Easily gather links to users' accounts on popular funding sites.
              </strong>
            </p>
            <p translate-context="Content/Home.Feature/Paragraph">
              Retribute scans enabled services for links to a range of different
              funding services - such as Liberapay, Patreon, Paypal, and
              Bandcamp - allowing you to easily spread the love!
            </p>
            <router-link
              :to="{
                name: 'platforms',
                params: { locale: $language.current }
              }"
            >
              <translate translate-context="Content/Home.Feature/Link"
                >See Currently Supported Platforms</translate
              >
            </router-link>
          </div>
          <div class="image">
            <img src="../assets/mascots/pollen.png" />
          </div>
        </div>
        <div class="feature">
          <div class="content">
            <h3>
              <translate translate-context="Content/Home.Feature/Header"
                >No tracking, no ads</translate
              >
            </h3>
            <p translate-context="Content/Home.Feature/Paragraph">
              <strong
                >Retribute is built with free, opens-source technologies and
                lets you keep control of your information</strong
              >
            </p>
            <p>
              <translate translate-context="Content/Home.Feature/Paragraph">
                All private and sensitive information is stored and processed in
                your web browser, including login information and suggestions.
                At the click of a button, this information can be completely
                removed.
              </translate>
            </p>
          </div>
          <div class="image"></div>
        </div>
      </section>
    </div>
    <div class="alternate row" id="get-started">
      <section class="wide">
        <h3 class="centered">
          <translate translate-context="Content/Home.Feature/Header"
            >Get Started</translate
          >
        </h3>
        <div class="pure-g">
          <div class="pure-u-1 pure-u-md-1-2">
            <h4 class="centered">
              <i class="fa fa-community"></i>&nbsp;
              <translate translate-context="Content/Home/Header"
                >For Supporters</translate
              >
            </h4>
            <p class="centered">
              <translate translate-context="Content/Home/Paragraph">
                Retribute keeps track of who you engage with and provides you
                with their donation links
              </translate>
              <br />
              <a class="pure-button" href="https://app.retribute.me/">
                <translate translate-context="Content/Home/Button.label"
                  >Try it now!</translate
                >
              </a>
            </p>
          </div>
          <div class="pure-u-1 pure-u-md-1-2">
            <h4 class="centered">
              <i class="fa fa-user"></i>&nbsp;
              <translate translate-context="Content/Home/Header"
                >For Recipients</translate
              >
            </h4>
            <p class="centered">
              <translate translate-context="Content/Home/Paragraph">
                Retribute delivers the contribution links listed on your
                accounts online to potential supporters
              </translate>
              <br />
              <router-link
                :to="{
                  name: 'platforms',
                  hash: '#donation-platforms',
                  params: { locale: $language.current }
                }"
              >
                <translate translate-context="Content/Home/Button.label"
                  >Add links to supported donation platforms</translate
                >
              </router-link>
            </p>
          </div>
        </div>
      </section>
    </div>
    <div class="row">
      <section>
        <h3>
          🐝️
          <translate translate-context="Content/Home/Header"
            >How does it work?</translate
          >
        </h3>
        <p>
          <strong>
            <translate translate-context="Content/Home/Paragraph"
              >Retribute connects to your existing accounts to suggest people to
              support.</translate
            >
          </strong>
          &nbsp;
          <translate translate-context="Content/Home/Paragraph"
            >Once you've signed in to your account, Retribute will assess your
            interactions with individuals and creators and assign them a weight
            based on the frequency of your interactions.</translate
          >
        </p>
        <p>
          <translate translate-context="Content/Home/Paragraph"
            >Retribute uses links found in individuals' profiles to generate a
            list of donation sources connected to that individual. As long as
            they have a valid link, Retribute will return it.</translate
          >
        </p>
        <p>
          <translate translate-context="Content/Home/Paragraph"
            >Once Retribute has sorted through your interactions, simply find
            the person you wish to support and follow the link to their support
            platform. It's that easy!</translate
          >
        </p>
        <router-link
          class="pure-button"
          :to="{
            name: 'platforms',
            params: { locale: $language.current }
          }"
        >
          <i class="fa fa-eur"></i>&nbsp;
          <translate translate-context="Content/Home/*"
            >See a list of currently supported platforms</translate
          >
        </router-link>
      </section>
    </div>
    <div class="row alternate" id="more">
      <section class="wide">
        <div class="feature">
          <div class="content">
            <h3>
              <translate translate-context="Content/Home.Feature/Header"
                >Get involved</translate
              >
            </h3>
            <p>
              <strong>
                <translate translate-context="Content/Home.Feature/Paragraph">
                  This project is part of the Funkwhale community.
                </translate>
              </strong>
            </p>
            <p>
              <translate translate-context="Content/Home.Feature/Paragraph">
                We're a friendly community of volunteers, and we develop
                Retribute in parallel of Funkwhale, a decentralized audio
                streaming platform.
              </translate>
            </p>
            <p>
              <translate translate-context="Content/Home.Feature/Paragraph">
                If you want to help, you're welcome!
              </translate>
            </p>
            <ul>
              <li>
                <router-link
                  :to="{ name: 'coc', params: { locale: $language.current } }"
                >
                  <translate translate-context="Content/Footer/List.Item"
                    >Code of Conduct</translate
                  >
                </router-link>
              </li>
              <li>
                <a
                  href="https://governance.funkwhale.audio/g/L4AfDozC/funkwhale-retribute"
                >
                  <translate translate-context="Content/Home.Feature/List.Item">
                    Forum
                  </translate>
                </a>
              </li>
              <li>
                <a
                  href="https://dev.funkwhale.audio/retribute.me/spec/merge_requests"
                >
                  <translate translate-context="Content/Home.Feature/List.Item">
                    Draft specifications
                  </translate>
                </a>
              </li>
              <li>
                <a href="https://funkwhale.audio/support-us">
                  <translate translate-context="Content/Home/*"
                    >Support Us</translate
                  >
                </a>
              </li>
            </ul>
          </div>
          <div class="image">
            <img src="../assets/mascots/betty.png" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageMixin from "../PageMixin";

export default {
  mixins: [PageMixin]
};
</script>
