import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import GetTextPlugin from "vue-gettext";
import locales from "@/locales";
import "./setup";

Vue.config.productionTip = false;

let availableLanguages = (function() {
  let l = {};
  locales.locales.forEach(c => {
    l[c.code] = c.label;
  });
  return l;
})();
let defaultLanguage = "en_US";
Vue.use(GetTextPlugin, {
  availableLanguages: availableLanguages,
  defaultLanguage: defaultLanguage,
  languageVmMixin: {
    computed: {
      currentKebabCase: function() {
        return this.current.toLowerCase().replace("_", "-");
      }
    }
  },
  translations: {},
  silent: true
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
