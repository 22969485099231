<template>
  <div class="navigation-wrapper">
    <nav
      role="navigation"
      :aria-label="labels.ariaLabel"
      class="main pure-menu pure-menu-horizontal"
    >
      <router-link
        class="pure-menu-heading"
        :to="{ name: 'home' }"
        :title="labels.homeTitle"
      >
        <div style="display: flex; align-items: center;">
          <img
            alt="Retribute Logo"
            src="../assets/logos/logo_fleur.png"
            @click="showMenu - !showMenu"
          />&nbsp;
          <span>
            Retribute
          </span>
        </div>
      </router-link>
      <button class="pure-button menu-button" @click="showMenu = !showMenu">
        <i v-if="showMenu" class="fa fa-close"></i>
        <i v-else class="fa fa-bars"></i>
      </button>
      <ul :class="['pure-menu-list', { hide: !showMenu }]">
        <li class="pure-menu-item">
          <router-link
            class="pure-menu-link"
            :to="{ name: 'platforms', params: { locale: $language.current } }"
          >
            <translate translate-context="Navigation/Link"
              >Supported Platforms</translate
            >
          </router-link>
        </li>
        <li class="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
          <a href="#" id="menuLink1" class="pure-menu-link">
            <translate translate-context="Navigation/Link">Resources</translate>
          </a>
          <ul class="pure-menu-children">
            <li class="pure-menu-item">
              <router-link
                class="pure-menu-link"
                :to="{
                  name: 'collective',
                  params: { locale: $language.current }
                }"
              >
                <translate translate-context="Content/Footer/List.Item"
                  >Collective and statutes</translate
                >
              </router-link>
            </li>
            <li class="pure-menu-item">
              <router-link
                class="pure-menu-link"
                :to="{ name: 'coc', params: { locale: $language.current } }"
              >
                <translate translate-context="Content/Footer/List.Item"
                  >Code of Conduct</translate
                >
              </router-link>
            </li>
            <li class="pure-menu-item">
              <a
                href="https://governance.funkwhale.audio/g/L4AfDozC/funkwhale-retribute"
                target="_blank"
                class="pure-menu-link"
              >
                <translate translate-context="Navigation/Link">Forum</translate>
              </a>
            </li>
            <li class="pure-menu-item">
              <a
                href="https://dev.funkwhale.audio/retribute.me"
                target="_blank"
                class="pure-menu-link"
              >
                <translate translate-context="Navigation/Link"
                  >Source code and specifications</translate
                >
              </a>
            </li>
            <li class="pure-menu-item">
              <router-link
                class="pure-menu-link"
                :to="{ name: 'contact', params: { locale: $language.current } }"
              >
                <translate translate-context="Content/Footer/List.Item"
                  >Contact</translate
                >
              </router-link>
            </li>
            <li class="pure-menu-item">
              <a
                class="pure-menu-link"
                href="https://funkwhale.audio/support-us"
                target="_blank"
              >
                <translate translate-context="Content/Footer/List.Item"
                  >Support us</translate
                >
              </a>
            </li>
          </ul>
        </li>
        <li class="pure-menu-item">
          <span class="pure-menu-link">
            <i class="fa fa-language"></i>&nbsp;
            <language-switcher />
          </span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import LanguageSwitcher from "./LanguageSwitcher";

export default {
  components: {
    LanguageSwitcher
  },
  data() {
    return {
      showMenu: false
    };
  },
  created() {
    let self = this;
    this.$router.afterEach(() => {
      self.showMenu = false;
    });
  },
  computed: {
    labels() {
      return {
        ariaLabel: this.$pgettext("Navigation/Aria.Label", "Main navigation"),
        homeTitle: this.$pgettext("Navigation/Link.Title", "Return to homepage")
      };
    }
  }
};
</script>
