<template>
  <div id="app">
    <navigation />
    <main>
      <router-view :key="$route.path" />
    </main>
    <footer class="main-footer">
      <div class="very wide wrapper">
        <div class="pure-g">
          <div class="pure-u-1 pure-u-md-1-4">
            <h5>
              <translate translate-context="Content/Footer/Header"
                >About us</translate
              >
            </h5>
            <div class="pure-menu custom-restricted-width">
              <ul class="pure-menu-list">
                <li>
                  <router-link
                    :to="{
                      name: 'collective',
                      params: { locale: $language.current }
                    }"
                  >
                    <translate translate-context="Content/Footer/List.Item"
                      >Collective and statutes</translate
                    >
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'coc',
                      params: { locale: $language.current }
                    }"
                  >
                    <translate translate-context="Content/Footer/List.Item"
                      >Code of Conduct</translate
                    >
                  </router-link>
                </li>
                <li>
                  <a
                    href="https://funkwhale.audio/en_US/support-us"
                    target="_blank"
                  >
                    <translate translate-context="Content/Footer/List.Item"
                      >Support us</translate
                    >
                  </a>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'contact',
                      params: { locale: $language.current }
                    }"
                  >
                    <translate translate-context="Content/Footer/List.Item"
                      >Contact</translate
                    >
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="pure-u-1 pure-u-md-1-4">
            <h5>
              <translate translate-context="Content/Footer/Header"
                >Links</translate
              >
            </h5>
            <div class="pure-menu custom-restricted-width">
              <ul class="pure-menu-list">
                <li class="pure-menu-item">
                  <a
                    href="https://governance.funkwhale.audio/g/L4AfDozC/funkwhale-retribute"
                  >
                    <translate translate-context="Navigation/Link"
                      >Forum</translate
                    >
                  </a>
                </li>
                <li class="pure-menu-item">
                  <a href="https://dev.funkwhale.audio/retribute.me">
                    <translate translate-context="Navigation/Link"
                      >Source code and specifications</translate
                    >
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="pure-u-1 pure-u-md-1-4">
            <h5>
              <label for="language" class="label">
                <i class="fa fa-language"></i>&nbsp;
                <translate translate-context="Label for language switcher"
                  >Language</translate
                >
              </label>
            </h5>
            <language-switcher id="language" />
            <a href="#">
              <translate translate-context="Navigation/Link"
                >Back to top</translate
              >
            </a>
          </div>
          <div class="pure-u-1 pure-u-md-1-4">
            <h5>
              <translate translate-context="Footer/Title">Credits</translate>
            </h5>
            <p>
              <translate translate-context="Footer/Paragraph"
                >Retribute is part of the Funkwhale community</translate
              >
            </p>
            <p>
              <translate translate-context="Footer/Paragraph"
                >The illustrations, logo, and mascot were kindly drawn by
                Robin.</translate
              >
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
require("typeface-noto-sans");
require("typeface-montserrat");

import Navigation from "./components/Navigation";
import LanguageSwitcher from "./components/LanguageSwitcher";

export default {
  components: {
    Navigation,
    LanguageSwitcher
  }
};
</script>

<style lang="scss">
@import "./style/main";
</style>
