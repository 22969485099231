var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('navigation'),_c('main',[_c('router-view',{key:_vm.$route.path})],1),_c('footer',{staticClass:"main-footer"},[_c('div',{staticClass:"very wide wrapper"},[_c('div',{staticClass:"pure-g"},[_c('div',{staticClass:"pure-u-1 pure-u-md-1-4"},[_c('h5',[_c('translate',{attrs:{"translate-context":"Content/Footer/Header"}},[_vm._v("About us")])],1),_c('div',{staticClass:"pure-menu custom-restricted-width"},[_c('ul',{staticClass:"pure-menu-list"},[_c('li',[_c('router-link',{attrs:{"to":{
                    name: 'collective',
                    params: { locale: _vm.$language.current }
                  }}},[_c('translate',{attrs:{"translate-context":"Content/Footer/List.Item"}},[_vm._v("Collective and statutes")])],1)],1),_c('li',[_c('router-link',{attrs:{"to":{
                    name: 'coc',
                    params: { locale: _vm.$language.current }
                  }}},[_c('translate',{attrs:{"translate-context":"Content/Footer/List.Item"}},[_vm._v("Code of Conduct")])],1)],1),_c('li',[_c('a',{attrs:{"href":"https://funkwhale.audio/en_US/support-us","target":"_blank"}},[_c('translate',{attrs:{"translate-context":"Content/Footer/List.Item"}},[_vm._v("Support us")])],1)]),_c('li',[_c('router-link',{attrs:{"to":{
                    name: 'contact',
                    params: { locale: _vm.$language.current }
                  }}},[_c('translate',{attrs:{"translate-context":"Content/Footer/List.Item"}},[_vm._v("Contact")])],1)],1)])])]),_c('div',{staticClass:"pure-u-1 pure-u-md-1-4"},[_c('h5',[_c('translate',{attrs:{"translate-context":"Content/Footer/Header"}},[_vm._v("Links")])],1),_c('div',{staticClass:"pure-menu custom-restricted-width"},[_c('ul',{staticClass:"pure-menu-list"},[_c('li',{staticClass:"pure-menu-item"},[_c('a',{attrs:{"href":"https://governance.funkwhale.audio/g/L4AfDozC/funkwhale-retribute"}},[_c('translate',{attrs:{"translate-context":"Navigation/Link"}},[_vm._v("Forum")])],1)]),_c('li',{staticClass:"pure-menu-item"},[_c('a',{attrs:{"href":"https://dev.funkwhale.audio/retribute.me"}},[_c('translate',{attrs:{"translate-context":"Navigation/Link"}},[_vm._v("Source code and specifications")])],1)])])])]),_c('div',{staticClass:"pure-u-1 pure-u-md-1-4"},[_c('h5',[_c('label',{staticClass:"label",attrs:{"for":"language"}},[_c('i',{staticClass:"fa fa-language"}),_vm._v(" \n              "),_c('translate',{attrs:{"translate-context":"Label for language switcher"}},[_vm._v("Language")])],1)]),_c('language-switcher',{attrs:{"id":"language"}}),_c('a',{attrs:{"href":"#"}},[_c('translate',{attrs:{"translate-context":"Navigation/Link"}},[_vm._v("Back to top")])],1)],1),_c('div',{staticClass:"pure-u-1 pure-u-md-1-4"},[_c('h5',[_c('translate',{attrs:{"translate-context":"Footer/Title"}},[_vm._v("Credits")])],1),_c('p',[_c('translate',{attrs:{"translate-context":"Footer/Paragraph"}},[_vm._v("Retribute is part of the Funkwhale community")])],1),_c('p',[_c('translate',{attrs:{"translate-context":"Footer/Paragraph"}},[_vm._v("The illustrations, logo, and mascot were kindly drawn by\n              Robin.")])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }